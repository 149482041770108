<template>
  <form @submit.prevent="handleContactForm">
    <hr />
    <div class="form-group row align-items-center">
      <div class="col-xl-6 col-lg-12 p-l-0 retrait-input">
        <BaseInput
          v-model="informationInput.nom"
          :errorMessage="nomErrorMessage"
          :isInvalid="
            $v.informationInput.nom.$error || errors.nom !== undefined
          "
          label="Nom"
          placeholder="Nom"
          type="text"
          @input="$v.informationInput.nom.$touch()"
        />
      </div>
      <div class="col-xl-6 col-lg-12 p-r-0 retrait-input">
        <BaseInput
          v-model="informationInput.prenom"
          :errorMessage="prenomErrorMessage"
          :isInvalid="
            $v.informationInput.prenom.$error || errors.prenom !== undefined
          "
          :label="$t('common.surname')"
          :placeholder="$t('common.surname')"
          type="text"
          @input="$v.informationInput.prenom.$touch()"
        />
      </div>
      <div class="col-xl-6 col-lg-12 p-l-0 retrait-input">
        <BaseInput
          v-model="informationInput.phone"
          :errorMessage="phoneErrorMessage"
          :isInvalid="
            $v.informationInput.phone.$error || errors.phone !== undefined
          "
          :label="$t('common.number') + ' ' + operator.label"
          :placeholder="$t('common.number') + ' ' + operator.label"
          type="number"
          @input="$v.informationInput.phone.$touch()"
        />
      </div>
      <div class="col-xl-6 col-lg-12 p-r-0 retrait-input">
        <div class="col-sm-6 text-lg-right p-l-0 retrait-button-container">
          <button
            class="be-btn primary  badge-failure lg contact-button"
            @click.prevent="$emit('close')"
          >
            {{ $t("common.cancel") }}
          </button>
        </div>
        <div class="col-sm-6 text-lg-right p-r-0 retrait-button-container">
          <button
            :disabled="$v.$invalid || loading"
            :loading="loading"
            :show-loading="true"
            class="be-btn primary green lg contact-button"
          >
            {{ $t("common.save") }}
          </button>
        </div>
      </div>
    </div>

    <div class="form-group row"></div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import { phoneLengthByCallingCode } from "../../helpers/utils";

export default {
  name: "ContactForm",

  props: {
    contact: {
      default: null,
    },
    operator_id: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      errors: [],
      informationInput: {
        contacts_id: null,
        nom: null,
        prenom: null,
        phone: null,
        operator_id: null,
      },
    };
  },

  watch: {
    contact(value) {
      if (value !== null) this.setContactInput(value);
      else this.setContactInput();
    },
  },

  computed: {
    ...mapGetters("withdraw", ["getOperatorById"]),
    ...mapGetters("auth", ["calling_code"]),

    operator() {
      const operator = this.getOperatorById(this.operator_id);
      if (operator) return operator;
      return null;
    },

    inputPhoneLength() {
      if (this.operator) {
        if (this.operator.country) return this.operator.country.phoneLength;
      }
      return phoneLengthByCallingCode(this.calling_code);
    },
    nomErrorMessage() {
      if (this.$v.informationInput.nom.$error || this.errors.nom) {
        if (!this.$v.informationInput.nom.required) {
          return this.$t("utils.required.name");
        }
        if (this.errors.nom) {
          return this.errors.nom[0];
        }
      }
      return "";
    },
    prenomErrorMessage() {
      if (this.$v.informationInput.prenom.$error || this.errors.prenom) {
        if (!this.$v.informationInput.prenom.required) {
          return this.$t("utils.required.surname");
        }
        if (this.errors.prenom) {
          return this.errors.prenom[0];
        }
      }
      return "";
    },
    phoneErrorMessage() {
      if (this.$v.informationInput.phone.$error || this.errors.phone) {
        if (!this.$v.informationInput.phone.required) {
          return this.$t("utils.required.number");
        }
        if (
          !this.$v.informationInput.phone.minLength ||
          !this.$v.informationInput.phone.maxLength
        ) {
          return (
            this.$t("utils.required.numbers_length_before") +
            ` ${this.inputPhoneLength} ` +
            this.$t("utils.characters")
          );
        }
        if (this.errors.phone) {
          return this.errors.phone[0];
        }
      }
      return "";
    },
  },

  created() {
    if (this.contact) this.setContactInput(this.contact);
  },

  methods: {
    ...mapActions("withdraw", ["addContactInformation", "deleteInformation"]),

    handleContactForm() {
      this.$v.$touch();
      if (!this.$v.invalid && !this.loading) {
        this.loading = true;
        this.informationInput.operator_id = this.operator_id;
        if (this.contact) this.informationInput.contacts_id = this.contact.id;
        this.addContactInformation(this.informationInput)
          .then(information => {
            this.$emit("saving", information);
            this.loading = false;
          })
          .catch(err => {
            if (err.response.data.information) {
              this.$emit("saving", err.response.data.information);
              this.loading = false;
            }
            if (err.response.data.errors)
              this.errors = err.response.data.errors;
          });
      }
    },

    setContactInput(value) {
      this.informationInput.contacts_id = value ? value.id : null;
      this.informationInput.nom = value ? value.nom : null;
      this.informationInput.prenom = value ? value.prenom : null;

      this.errors = [];
      this.loading = false;
    },
  },

  validations() {
    return {
      informationInput: {
        nom: { required },
        prenom: { required },
        phone: {
          required,
          minLength: minLength(this.inputPhoneLength),
          maxLength: maxLength(this.inputPhoneLength),
        },
      },
    };
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <div class="row m-l-0 m-r-0 m-b-20">
      <div class="col-xl-6 col-lg-12 p-l-0 retrait-input">
        <slot />
      </div>
      <div v-if="operator_id" class="col-xl-5 col-lg-12 p-r-0 retrait-input">
        <be-multiselect
          v-model="contact_id"
          :close-on-select="true"
          :disabled="!operator_id"
          :inputLabel="$t('transaction.receiver')"
          :options="remappedContacts"
          :placeholder="$t('common.choose_o')"
          label="label"
          onlyKey
          track-by="id"
          @input="chooseContactInformation"
        />
      </div>
      <div v-if="operator_id" class="save-btn col-xs-1">
        <button
          :data-original-title="$t('common.delete_account')"
          :disabled="selectContact === null || showContactForm"
          :title="$t('common.delete_account')"
          class="be-btn secondary danger circle-btn lg"
          role="button"
          type="button"
          @click.prevent="removeContactInformation"
        >
          <i class="icon-person-delete"></i>
        </button>
      </div>
    </div>
    <div v-if="showContactForm && !closeContact" class="col-md-12 mt-1">
      <contact-form
        :contact="selectContact"
        :operator_id="operator_id"
        @close="removeContactForm"
        @saving="setInformation"
      />
    </div>
  </div>
</template>

<script>
import ContactForm from "./ContactForm";
import { mapActions, mapGetters, mapState } from "vuex";
import BeMultiselect from "../common/BeMultiselect";

export default {
  name: "ContactSelector",
  components: { ContactForm, BeMultiselect },

  data() {
    return {
      loading: false,
      contact_id: null,
      information: null,
      closeContact: false,
    };
  },
  props: {
    operator_id: {
      type: Number,
      default: null,
    },
  },
  watch: {
    information(value) {
      this.$emit("select", value);
    },
  },

  computed: {
    ...mapState("withdraw", ["operators", "contacts"]),
    ...mapGetters("withdraw", ["getContactById"]),
    selectContact() {
      const contact = this.getContactById(this.contact_id);
      if (contact) return contact;
      return null;
    },
    showContactForm() {
      if (this.selectContact) {
        const contactInfo = this.operatorContactInformation(
          this.selectContact.id
        );
        if (!contactInfo.id) return true;
      }
      return this.contact_id === -1;
    },
    remappedContacts() {
      const contacts = this.contacts.map(contact => ({
        label: `${contact.nom} ${contact.prenom} ${
          this.operatorContactInformation(contact.id).phone
        }`,
        id: contact.id,
      }));
      contacts.push({ label: this.$t("common.add_contact_with_plus"), id: -1 });
      return contacts;
    },
  },

  methods: {
    ...mapActions("withdraw", ["deleteInformation"]),

    operatorContactInformation(contact_id) {
      const contact = this.getContactById(contact_id);
      if (contact) {
        const info = contact.informations.find(
          inf => inf.operators_id === this.operator_id
        );
        if (info) return info;
      }
      return { phone: this.$t("common.not_defined") };
    },

    chooseContactInformation() {
      if (this.selectContact) {
        const contactInfo = this.operatorContactInformation(
          this.selectContact.id
        );
        if (contactInfo.id) this.information = contactInfo;
        else this.information = null;
      } else this.information = null;
      this.closeContact = false;
    },

    removeContactForm() {
      this.closeContact = true;
    },

    removeContactInformation() {
      if (!this.loading) {
        this.loading = true;
        this.deleteInformation(Object.assign(this.information)).then(() => {
          this.contact_id = null;
          this.loading = false;
          this.information = null;
        });
      }
    },

    setInformation(information) {
      const info = Object.assign({}, information);
      this.contact_id = info.contacts_id;
      this.information = info;
    },
  },
};
</script>

<style scoped></style>

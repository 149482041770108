<template>
  <div class="retraits-service be-row justify-content-center">
    <div class="left-side d-none d-xl-flex">
      <div class="icon-div">
        <i class="icon-withdraw-fill"></i>
      </div>
      <span>
        {{ $t("common.new") }} <br />
        {{ $t("withdraw.money_withdraw") }}
      </span>
    </div>
    <div class="right-side col-xl-8 px-0">
      <div class="be-card col-xl-12 px-0">
        <div class="position-relative">
          <header-with-divider
            :color="'blue'"
            :text="$t('withdraw.new_money_withdraw')"
          />
        </div>
        <div class="h-100">
          <div
            v-if="isWithdrawAvailable"
            class="be-card-body pt-0 pt-sm-3 pl-2 pr-2"
          >
            <div v-if="!isConfirmation && isAlertShown" class="col-lg-12">
              <div aria-hidden="true" class="alert alert-box-bwallet pb-0">
                <button
                  aria-label="Close"
                  class="close"
                  data-dismiss="alert"
                  type="button"
                  @click="closeAlert"
                >
                  <i class="icon-close" />
                </button>
                <b>{{ $t("withdraw.new_money_withdraw_fees") }}</b>
                <div class="mt-4 tax-info">
                  <p class="tax-success f-w-600 d-flex">
                    <i class="icon-arrow-down-light" />
                    {{ $t("withdraw.low_rate") }}
                    {{ userConfig.frais_retrait }}%
                  </p>
                  <p>{{ $t("withdraw.low_rate_desc") }}</p>
                </div>
                <div class="mt-4 tax-info">
                  <p class="tax-error f-w-600 d-flex">
                    <i class="icon-arrow-up-light" />
                    {{ $t("withdraw.high_rate") }}
                    {{ userConfig.frais_retrait_fort }}%
                  </p>
                  <p>{{ $t("withdraw.high_rate_desc") }}</p>
                </div>
              </div>
            </div>
            <template v-if="!isConfirmation">
              <div
                class="tax-container d-flex justify-content-between align-items-center"
              >
                <div
                  :title="$t('withdraw.high_rate')"
                  class="col p-0 pl-2 tax-success d-flex m-0"
                >
                  <i class="icon-arrow-down-light" />
                  {{
                    `${userStats.balance -
                      userStats.balance_low_rate} ${currency}`
                  }}
                </div>
                <div class="col-auto p-0 tax-title"><b>Taux</b></div>
                <div
                  :title="$t('withdraw.low_rate')"
                  class="col p-0 pr-2 justify-content-end tax-error d-flex m-0"
                >
                  <i class="icon-arrow-up-light" />
                  {{ `${userStats.balance_low_rate} ${currency}` }}
                </div>
              </div>
            </template>
            <form
              class="form m-20 position-relative"
              @submit.prevent="handleWithdrawForm"
            >
              <be-form-error v-if="error" :message="error" />
              <template v-if="!isConfirmation">
                <ContactSelector
                  :info="information"
                  :operator_id="operator_id"
                  @select="selectInformation"
                >
                  <be-multiselect
                    v-model="operator_id"
                    :close-on-select="true"
                    :options="operators"
                    :inputLabel="$t('common.choose_an_operator')"
                    label="label"
                    onlyKey
                    track-by="id"
                    withImages
                  />
                </ContactSelector>
                <hr />
                <div class="form-group row align-items-center">
                  <div class="col-xl-6 col-lg-12">
                    <BaseInput
                      v-model.number="amount"
                      :disabled="!information"
                      :errorMessage="amountErrMsg"
                      :isInvalid="$v.amount.$error"
                      :label="$t('common.withdraw_amount')"
                      :placeholder="$t('common.insert_amount')"
                      :rightText="!information ? '' : feePercent + '%'"
                      type="number"
                      @input="$v.amount.$touch()"
                    />
                    <small v-if="!amountErrMsg" class="form-text m-l-30">
                      {{ $t("common.balance_after_validation") }} :
                      <span class="f-w-600">
                        {{ `${balance} ${currency}` }}
                      </span>
                    </small>
                  </div>
                </div>
              </template>
              <template v-if="isConfirmation">
                <div
                  class="confirm-container d-flex flex-column align-items-center"
                >
                  <div class="shield-icon">
                    <i class="icon-shield-check" />
                  </div>
                  <h2 class="f-w-600 text-center">
                    {{ $t("withdraw.enter_password_to_confirm_withdraw") }}
                  </h2>
                  <h5 class="text-center">
                    {{ $t("withdraw.withdraw_amount_in_your_account_before") }}:
                    <b>{{ `${amountToPaid} ${currency}` }}</b>
                    {{ $t("common.for_this_operation") }}
                  </h5>
                  <div class="retrait-pass-container">
                    <BaseInputPassword
                      v-model="password"
                      :errorMessage="passErrMsg"
                      :isInvalid="$v.password.$error"
                      placeholder="Mot de passe"
                      @blur="$v.password.$touch()"
                    >
                    </BaseInputPassword>
                  </div>
                  <div class="text-center retraits-confirm-table">
                    <b-row>
                      <b-col>
                        <div class="label-text">
                          <div>{{ $t("common.receiver") }}</div>
                          <div class="f-w-600">
                            <span v-if="contactName">
                              {{ contactName | truncate(16, "...") }}
                            </span>
                            <span v-else>{{ $t("common.not_specified") }}</span>
                          </div>
                        </div>
                      </b-col>
                      <b-col>
                        <div class="label-text">
                          <div>{{ $t("common.amount") }}</div>
                          <div class="f-w-600">
                            {{ `${amount} ${currency}` }}
                          </div>
                        </div>
                      </b-col>
                      <b-col>
                        <div class="label-text">
                          <div>{{ $t("common.operator") }}</div>
                          <div class="f-w-600">{{ operatorName }}</div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col>
                        <div class="label-text">
                          <div>{{ $t("common.phone_number") }}</div>
                          <div class="f-w-600">{{ contactPhone }}</div>
                        </div>
                      </b-col>
                      <b-col>
                        <div class="label-text">
                          <div>{{ $t("common.fees") }} ({{ feePercent }}%)</div>
                          <div class="f-w-600">{{ `${fees} ${currency}` }}</div>
                        </div>
                      </b-col>
                      <b-col>
                        <div class="label-text">
                          <div>{{ $t("common.status") }}</div>
                          <div class="f-w-600">{{ $t("common.pending") }}</div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </template>
              <div class="form-group mb-0 text-lg-right">
                <hr />
                <div
                  class="retrait-main-buttons row justify-content-end align-items-center mr-md-2 m-t-20"
                >
                  <span
                    class="f-w-600 fermer pointer retrait-annuler"
                    @click="$router.push({ name: 'withdraw' }).catch(() => {})"
                  >
                    {{ $t("common.cancel") }}
                  </span>
                  <button
                    :class="isConfirmation ? 'green' : 'blue'"
                    :disabled="$v.$invalid || loading"
                    class="ml-4 be-btn primary lg"
                  >
                    {{
                      isConfirmation ? $t("common.confirm") : $t("common.start")
                    }}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <no-content
            v-else
            :text="$t('withdraw.no_withdraw_done')"
            icon="icon-withdraw"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ContactSelector from "../../components/withdraw/ContactSelector";
import {
  required,
  minValue,
  maxValue,
  requiredIf,
  minLength,
} from "vuelidate/lib/validators";
import { serverApiCode, typeServiceCode } from "../../helpers/constants";
import NoContent from "../../components/common/NoContent";
import HeaderWithDivider from "../../components/common/HeaderWithDivider";
import BeMultiselect from "../../components/common/BeMultiselect";
import BeFormError from "../../components/common/BeFormError";
import { getAmountValidationStep } from "../../helpers/utils";

export default {
  name: "WithdrawForm",
  components: {
    ContactSelector,
    HeaderWithDivider,
    NoContent,
    BeMultiselect,
    BeFormError,
  },
  data() {
    return {
      operator_id: null,
      loading: false,
      isConfirmation: false,
      error: null,
      information: null,
      amount: null,
      password: null,
      errors: [],
      isAlertShown: true,
    };
  },

  watch: {
    operator_id() {
      const operator = this.operators.find(o => o.id === this.operator_id);
      if (operator !== undefined) {
        if (operator.api_retrait === serverApiCode.visa_redirect) {
          this.loading = true;
          this.$router.push({
            name: "shopping.form",
            params: {
              type_code: typeServiceCode.charge_card.toLowerCase(),
            },
          });
        }
      }
    },
  },

  computed: {
    ...mapState("withdraw", ["operators", "metaWithdraw"]),
    ...mapGetters("withdraw", ["isWithdrawAvailable"]),
    ...mapGetters("auth", ["userConfig", "userStats", "currency"]),
    passErrMsg() {
      if (this.$v.password.$error || this.errors.password) {
        if (!this.$v.password.required) {
          return this.$t("common.insert_password");
        }
        if (!this.$v.password.minLength) {
          return this.$t("common.password_required_4_digits");
        }
      }
      return "";
    },
    amountErrMsg() {
      if (this.$v.amount.$error) {
        if (!this.$v.amount.required) {
          return this.$t("withdraw.insert_amount");
        }
        if (!this.$v.amount.minValue) {
          return (
            this.$t("withdraw.greatest_amount_required") +
            ` ${this.userConfig.retrait_min} ${this.currency}`
          );
        }
        if (!this.$v.amount.maxValue) {
          return (
            this.$t("withdraw.insert_amount") +
            ` ${this.maxAmountValue} ${this.currency}`
          );
        }
        if (!this.$v.amount.validAmount) {
          return this.$t("withdraw.required_valid_amount");
        }
      }
      return "";
    },
    selectContact() {
      const contact = this.getContactById(this.contact_id);
      if (contact) return contact;
      return null;
    },
    feePercent() {
      if (this.userConfig.frais_retrait === 0) return 0;
      else {
        if (this.amount >= this.userStats.balance_low_rate)
          return this.userConfig.frais_retrait_fort;
        else return this.userConfig.frais_retrait;
      }
    },
    fees() {
      if (this.feePercent === 0) return 0;
      else
        return (
          (parseFloat(this.amount) * parseFloat(this.feePercent)) /
          100
        ).toFixed(0);
    },
    amountToPaid() {
      return this.amount
        ? parseFloat(this.amount) + parseFloat(this.fees)
        : null;
    },
    balance() {
      if (this.amountToPaid)
        return (
          parseFloat(this.userStats.balance) - parseFloat(this.amountToPaid)
        );
      else return this.userStats.balance;
    },
    maxAmountValue() {
      return this.userStats.balance > this.userConfig.retrait_max
        ? this.userConfig.retrait_max
        : this.userStats.balance;
    },
    contactName() {
      if (this.information)
        return this.information.contact
          ? (this.information.contact.nom || "") +
              " " +
              (this.information.contact.prenom || "")
          : null;
      return null;
    },
    contactPhone() {
      if (this.information && this.information.operator)
        return this.information.operator.country
          ? `+${this.information.operator.country.calling_code} ${this.information.phone}` ||
              ""
          : null;
      return null;
    },
    operatorName() {
      if (this.information)
        return this.information.operator
          ? this.information.operator.label || ""
          : null;
      return null;
    },
    isIntouchWithdraw() {
      return this.information
        ? this.information.operator.api_retrait === serverApiCode.intouch
        : false;
    },
    amountValidationStep() {
      return this.information
        ? getAmountValidationStep(this.information.operator.api_retrait)
        : 100;
    },
  },

  methods: {
    selectInformation(information) {
      this.amount = null;
      this.information = information;
    },
    closeAlert() {
      this.isAlertShown = false;
    },
    handleWithdrawForm() {
      this.loading = true;
      this.error = null;
      if (this.isConfirmation) {
        this.$store
          .dispatch("withdraw/confirmWithdraw", {
            information: this.information,
            amount: this.amount,
            password: this.password,
          })
          .then(() => {
            this.$router.push({ name: "withdraw" });
            this.loading = false;
          })
          .catch(error => {
            if (error.message) {
              this.error = error.message;
            }
            this.loading = false;
            this.password = null;
          });
      } else {
        this.isConfirmation = true;
        this.loading = false;
        this.error = null;
      }
    },
  },

  validations() {
    return {
      operator_id: { required },
      information: { required },
      amount: {
        required,
        minValue: minValue(this.userConfig.retrait_min),
        maxValue: maxValue(this.maxAmountValue),
        validAmount: value => {
          if (value) {
            return this.isIntouchWithdraw
              ? parseFloat(this.amount) % this.amountValidationStep === 0
              : true;
          } else return true;
        },
      },
      password: {
        required: requiredIf(function(model) {
          if (this.isConfirmation) return model;
          else return false;
        }),
        minLength: minLength(4),
      },
    };
  },
};
</script>

<style scoped></style>
